"use client";

import { useEffect, useState } from "react";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import {
  CommandBar,
  CommandBarDialog,
  CommandBarContent,
  CommandBarInput,
  CommandBarList,
  CommandBarGroup,
  CommandBarItem,
  CommandBarEmpty,
} from "@saas-ui/command-bar";
import { User, useUserListQuery, UserLevel } from "@/lib/user";
import {
  alertError,
  CircularPendingElement,
  loadingFrame,
  QuestionMarkElement,
} from "./LoadingFrame";
import { usePretend } from "@/lib/pretend";
import { Assignment, useAssignmentQuery } from "@/lib/assignment";
import { useNavigate } from "react-router-dom";
import {
  adminButtonGroup,
  labButtons,
  LinkButton,
  LinkButtonGroup,
  projectButtons,
  queueButtonGroup,
} from "@/lib/navigation";

function CommandPropmt({ onClick }: { onClick: () => void }) {
  return <Button onClick={onClick}>CMD</Button>;
}

function CommandButton({
  button,
  search,
}: {
  button: LinkButton;
  search: string;
}) {
  const navigate = useNavigate();
  return (
    <CommandBarItemWrapper
      key={button.key}
      value={button.key}
      onSelect={() => {
        navigate(button.url);
      }}
      search={search}
    >
      {button.name}
    </CommandBarItemWrapper>
  );
}

function CommandButtonGroup({
  buttonGroup,
  search,
}: {
  buttonGroup: LinkButtonGroup;
  search: string;
}) {
  return (
    <CommandBarGroup heading={buttonGroup.name}>
      {buttonGroup.buttons.map((button: LinkButton) => (
        <CommandButton button={button} key={button.key} search={search} />
      ))}
    </CommandBarGroup>
  );
}

function related(search: string, value: string) {
  const formatSearch = search.trim().replace(" ", "").toLowerCase();
  const formatValue = value.trim().replace(" ", "").toLowerCase();
  if (formatValue.startsWith(formatSearch)) {
    return true;
  }
  const replacedValue = formatValue
    .replaceAll("project", "p")
    .replaceAll("lab", "l")
    .replaceAll("part", "")
    .replaceAll(" ", "");
  if (replacedValue.startsWith(formatSearch)) {
    return true;
  }
  return false;
}

function CommandBarItemWrapper(props: any) {
  const search: string = props.search;
  const value: string = props.value;
  return related(search, value) ? <CommandBarItem {...props} /> : <></>;
}

function PretendCommand({ search }: { search: string }) {
  const userListQuery = useUserListQuery();
  const navigate = useNavigate();
  const toast = useToast();
  const view = loadingFrame(
    userListQuery,
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (userList) => (
      <>
        <CommandBarGroup heading="Go to student projects">
          <CommandBarItemWrapper
            value="reset"
            onSelect={() => navigate("/")}
            search={search}
          >
            Reset
          </CommandBarItemWrapper>
          {userList.map((user: User) => (
            <CommandBarItemWrapper
              key={"pretend" + user.uniqname}
              value={user.uniqname}
              search={search}
              onSelect={() => {
                navigate("/?pretend=" + user.uniqname);
              }}
            >
              {user.uniqname}
            </CommandBarItemWrapper>
          ))}
        </CommandBarGroup>
      </>
    ),
  );
  return view;
}
function CommandButtons({ search }: { search: string }) {
  const toast = useToast();
  const buttonView = loadingFrame(
    useAssignmentQuery(),
    <></>,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (assignments: Array<Assignment>) => (
      <UserLevel
        notLogIn={<></>}
        student={
          <>
            <CommandButtonGroup
              buttonGroup={projectButtons(assignments)}
              search={search}
            />
            <CommandButtonGroup
              buttonGroup={labButtons(assignments)}
              search={search}
            />
            <CommandButtonGroup
              buttonGroup={queueButtonGroup}
              search={search}
            />
          </>
        }
        staff={
          <>
            <PretendCommand search={search} />
            <CommandButtonGroup
              buttonGroup={projectButtons(assignments)}
              search={search}
            />
            <CommandButtonGroup
              buttonGroup={labButtons(assignments)}
              search={search}
            />
            <CommandButtonGroup
              buttonGroup={queueButtonGroup}
              search={search}
            />
            <CommandButtonGroup
              buttonGroup={adminButtonGroup}
              search={search}
            />
          </>
        }
      />
    ),
  );

  return <>{buttonView}</>;
}

export function CommandPalette() {
  const [inputValue, onInputValueChange] = useState("");
  const { isOpen, onClose, onToggle } = useDisclosure();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "j" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onInputValueChange("");
        onToggle();
      }
      if (e.key === "Escape") {
        e.preventDefault();
        onClose();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  });

  return (
    <>
      <p className="text-sm text-muted-foreground">
        {/* Press{" "} */}
        <CommandPropmt onClick={onToggle} />
      </p>
      <CommandBar
        isOpen={isOpen}
        onClose={onClose}
        closeOnSelect
        shouldFilter={false}
      >
        <CommandBarDialog>
          <CommandBarContent>
            <CommandBarInput
              placeholder="Type a command or search..."
              value={inputValue}
              onChange={onInputValueChange}
              autoFocus
            />

            <CommandBarList>
              <CommandBarEmpty>No results found.</CommandBarEmpty>
              <CommandButtons search={inputValue} />
            </CommandBarList>
          </CommandBarContent>
        </CommandBarDialog>
      </CommandBar>
    </>
  );
}
