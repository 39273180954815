import {
  alertError,
  CircularPendingElement,
  loadingFrame,
  QuestionMarkElement,
  useAlertError,
} from "@/components/LoadingFrame";
import SlimContainer from "@/components/SlimContainer";
import {
  Grader,
  Server,
  useGraderList,
  useServerActivationToggle,
  useServerList,
} from "@/lib/server";
import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function ServerModal({ server }: { server: Server }) {
  var toggledServer: Server = structuredClone(server);
  toggledServer.isActive = !toggledServer.isActive;
  const graderListQuery = useGraderList(server.hostname);
  const toast = useToast();
  const graderView = loadingFrame(
    graderListQuery,
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (graders: Array<Grader>) => {
      const expandAll = graders.map((_, idx) => idx);
      return (
        <Accordion width="100%" allowMultiple defaultIndex={expandAll}>
          {graders.map((grader: Grader) => (
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Grader: {grader.graderId}
                    {grader.finalGrader ? "*" : ""}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                Grading:{" "}
                {grader.gradingUniqname !== null ? (
                  <Link
                    to={
                      "/assignment/" +
                      grader.gradingProjectId +
                      "?pretend=" +
                      grader.gradingUniqname
                    }
                  >
                    {grader.gradingProjectId +
                      " " +
                      grader.gradingUniqname +
                      " " +
                      grader.gradingTimestamp}
                  </Link>
                ) : (
                  "Nothing"
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      );
    },
  );
  const useServerEdit = useServerActivationToggle();
  useAlertError(useServerEdit);
  return (
    <ModalContent>
      <ModalHeader>Server {server.hostname}</ModalHeader>
      <ModalBody>
        <VStack width="100%" align="start">
          <Text>Status: {server.isActive ? "Active" : "Inactive"} </Text>
          <Text>Primary: {server.isPrimary ? "Yes" : "No"}</Text>
          <Box width="100%">{graderView}</Box>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <VStack align="end">
          <Center>
            <Text>*: Final grader</Text>
          </Center>
          <Button
            colorScheme="teal"
            onClick={() => {
              useServerEdit.mutate(toggledServer);
            }}
            isLoading={useServerEdit.isPending}
          >
            {server.isActive
              ? "Deactivate " + server.hostname
              : "Activate " + server.hostname}
          </Button>
        </VStack>
      </ModalFooter>
    </ModalContent>
  );
}

function ServerItem({ server }: { server: Server }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Stack direction={["column", "row"]} width="100%">
      <Box>
        <Text fontSize="xx-large">{server.hostname}</Text>
        <Text>{server.isActive ? "Active" : "Inactive"}</Text>
      </Box>
      <Spacer />
      <Center>
        <Button colorScheme="teal" onClick={onOpen}>
          Status
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ServerModal server={server} />
        </Modal>
      </Center>
    </Stack>
  );
}

export default function ServerAdminList() {
  const serverList = useServerList();
  const toast = useToast();
  const serverListView = loadingFrame(
    serverList,
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (serverList: Array<Server>) => (
      <VStack width="100%" divider={<StackDivider />}>
        {serverList.map((server: Server) => (
          <ServerItem server={server} key={server.hostname} />
        ))}
      </VStack>
    ),
  );
  return <SlimContainer>{serverListView}</SlimContainer>;
}
