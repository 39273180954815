import { AssignmentNavigationButton } from "@/components/AssignmentNavigationButtons";
import { CodeBlock } from "@/components/CodeBlock";
import {
  alertError,
  CircularPendingElement,
  loadingFrame,
  QuestionMarkElement,
} from "@/components/LoadingFrame";
import SlimContainer from "@/components/SlimContainer";
import { useFileQuery } from "@/lib/file";
import { useSubmissionQuery } from "@/lib/submission";
import {
  Stack,
  Text,
  Center,
  AlertIcon,
  Alert,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

export default function File() {
  const { assignmentId, timestamp, fileName, destServer } = useParams();
  const codeQuery = useFileQuery(
    assignmentId || "UNDEFINED",
    timestamp || "UNDEFINED",
    fileName || "UNDEFINED",
    destServer || "UNDEFINED",
  );
  const submission = useSubmissionQuery(
    assignmentId || "UNDEFINED",
    timestamp || "UNDEFINED",
  );
  const extraBanner =
    submission.data !== undefined && submission.data.isExtra ? (
      <Alert status="warning">
        <AlertIcon />
        This was an extra submit. It didn't count toward your daily submission
        limit. However: if this was your last submit, it is still eligible for
        final grading.
      </Alert>
    ) : (
      <></>
    );
  const toast = useToast();
  const codeView = loadingFrame(
    codeQuery,
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (code: string) => (
      <>
        {extraBanner}
        {code === "" ? (
          <Center>
            <Text fontSize="xxx-large" color="gray">
              File Empty
            </Text>
          </Center>
        ) : (
          <CodeBlock codeString={code} fileName={fileName || "UNDEFINED"} />
        )}
      </>
    ),
  );
  return (
    <SlimContainer>
      <Stack direction="column">
        <Text fontSize="x-large">{assignmentId}</Text>
        <Text fontSize="large">Submission: {timestamp}</Text>
        <Text fontSize="large">{fileName}</Text>
        <AssignmentNavigationButton
          assignmentId={assignmentId || "UNDEFINED"}
          timestamp={timestamp || "UNDEFINED"}
          destServer={destServer || "UNDEFINED"}
        />
        {codeView}
      </Stack>
    </SlimContainer>
  );
}
