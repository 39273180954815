import { LinkWithPretend, pretendUrl, usePretend } from "@/lib/pretend";
import { isInstructor, useUserQuery } from "@/lib/user";
import { Stack, Button } from "@chakra-ui/react";
import { saveAs } from "file-saver";

async function downloadFile(
  assignmentId: string,
  timestamp: string,
  destServer: string,
  pretend: string | null,
) {
  const url =
    "/api/v1/file/submission/" + assignmentId + "/" + timestamp + "/submit.tgz";
  const response = await fetch(pretendUrl(url, pretend), {
    headers: { destServer: destServer },
  });
  const data = await response.blob();
  return saveAs(data, assignmentId + "-" + timestamp + ".tgz");
}

export function AssignmentNavigationButton({
  assignmentId,
  timestamp,
  destServer,
}: {
  assignmentId: string;
  timestamp: string;
  destServer: string;
}) {
  const { data: user } = useUserQuery();
  const { pretend } = usePretend();
  const moreButtons = isInstructor(user) ? (
    <>
      <Button
        as={LinkWithPretend}
        to={"/filelist/" + assignmentId + "/" + timestamp + "/" + destServer}
      >
        View Files
      </Button>
    </>
  ) : (
    <></>
  );
  return (
    <Stack direction={["column", "row"]} width="100%">
      <Button as={LinkWithPretend} to={"/assignment/" + assignmentId}>
        Back to Project
      </Button>
      {moreButtons}
      <Button
        colorScheme="teal"
        onClick={() =>
          downloadFile(assignmentId, timestamp, destServer, pretend)
        }
      >
        Download Submission
      </Button>
    </Stack>
  );
}
