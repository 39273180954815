import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import alertMsgStyle from "./error";
export type Extension = {
  uniqname: string;
  projectId: string;
  closingTime: string;
  comments: string;
  extensionGranter: string | null;
  createdAt: string | null;
};

export const queryExtension = async (assignmentId: string | undefined) => {
  const response = await fetch("/api/v1/extension/admin/" + assignmentId);
  if (response.status !== 200) {
    throw new Error(
      alertMsgStyle("Fetching Extension List Failed.", response.status),
    );
  }
  const extensions = await response.json();
  return Object.assign(new Array<Extension>(), extensions);
};

export const useQueryExtension = (assignmentId: string | undefined) =>
  useQuery({
    queryKey: ["user", "assignment", "extensionList", assignmentId],
    queryFn: async () => await queryExtension(assignmentId),
    refetchOnWindowFocus: false,
  });

export const useExtensionApply = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (extension: Extension) => {
      const response = await fetch("/api/v1/extension/admin/", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(extension),
      });
      const result = (await response.json()) as Extension;
      return result;
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "assignment", "extensionList"],
      }),
  });
};

export const useExtensionLateDayApply = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignmentId: string) => {
      const response = await fetch(
        "/api/v1/extension/student/" + assignmentId,
        { method: "post" },
      );
      if (response.status !== 200) {
        throw new Error(
          alertMsgStyle("Cannot apply for a late day.", response.status),
        );
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "assignmentDetail"],
      }),
  });
};
