import { Assignment, detailsPage, isProject } from "./assignment";

export type LinkButton = {
  name: string;
  url: string;
  key: string;
};

export type LinkButtonGroup = {
  name: string;
  key: string;
  buttons: Array<LinkButton>;
};

export function projectButtons(assignments: Array<Assignment>) {
  const buttons = assignments.filter(isProject).map(
    (assignment: Assignment) =>
      ({
        name: assignment.projectName,
        url: detailsPage(assignment),
        key: assignment.projectName + assignment.projectId,
      }) as LinkButton,
  );
  return {
    name: "Projects",
    key: "projects",
    buttons: buttons,
  } as LinkButtonGroup;
}

export function labButtons(assignments: Array<Assignment>) {
  const buttons = assignments
    .filter((assignment: Assignment) => !isProject(assignment))
    .map(
      (assignment: Assignment) =>
        ({
          name: assignment.projectName,
          url: detailsPage(assignment),
          key: assignment.projectName + assignment.projectId,
        }) as LinkButton,
    );
  return { name: "Labs", key: "labs", buttons: buttons } as LinkButtonGroup;
}

const adminButtons: Array<LinkButton> = [
  {
    key: "assignment list",
    url: "/assignmentadminlist",
    name: "Assignment List",
  },
  { key: "students", url: "/studentadmin", name: "Students" },
  { key: "servers", url: "/serveradminlist", name: "Servers" },
];

export const adminButtonGroup: LinkButtonGroup = {
  key: "adminButtons",
  buttons: adminButtons,
  name: "Admin",
};

const queueButtons: Array<LinkButton> = [
  {
    key: "queue",
    url: "/queue/gradingQueue",
    name: "Queue",
  },
  {
    key: "finalGradingQueue",
    url: "/queue/finalGradingQueue",
    name: "Final Grading Queue",
  },
];
export const queueButtonGroup: LinkButtonGroup = {
  key: "queueButtons",
  buttons: queueButtons,
  name: "Queue",
};
