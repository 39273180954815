import { Assignment, useAssignmentEdit } from "@/lib/assignment";
import {
  Box,
  StackDivider,
  Text,
  VStack,
  Flex,
  Spacer,
  Center,
  Button,
  HStack,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  ModalOverlay,
  useDisclosure,
  FormControl,
  Checkbox,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import SlimContainer from "@/components/SlimContainer";
import {
  alertError,
  CircularPendingElement,
  loadingFrame,
  QuestionMarkElement,
  useAlertError,
} from "@/components/LoadingFrame";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAssignmentQuery } from "@/lib/assignment";
import { LinkWithPretend } from "@/lib/pretend";
import { formatDate } from "@/lib/datetime";

interface EditFormValues {
  isVisible: boolean;
}

function AssignmentEditModal({ assignment }: { assignment: Assignment }) {
  const defaultEditFormValues: EditFormValues = {
    isVisible: assignment.isVisible === "1",
  };

  const { handleSubmit, register } = useForm<EditFormValues>({
    defaultValues: defaultEditFormValues,
  });
  const assignmentEdit = useAssignmentEdit();
  useAlertError(assignmentEdit);
  const submit: SubmitHandler<EditFormValues> = async (data) => {
    var newAssignment = assignment;
    newAssignment.isVisible = data.isVisible ? "1" : "0";
    assignmentEdit.mutate({
      assignmentId: assignment.projectId,
      assignment: newAssignment,
    });
  };
  return (
    <ModalContent as="form" onSubmit={handleSubmit(submit)}>
      <ModalHeader>
        Edit {assignment.projectId}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <FormControl>
          <Checkbox
            defaultChecked={assignment.isVisible === "1"}
            id="isVisible"
            {...register("isVisible")}
          >
            Visible
          </Checkbox>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <HStack spacing={2}>
          <Button>Benchmarks</Button>
          <Button
            colorScheme="teal"
            type="submit"
            isLoading={assignmentEdit.isPending}
          >
            Confirm
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
}

function AssignmentItem({ assignment }: { assignment: Assignment }) {
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  return (
    <Box key={assignment.projectId} width="100%">
      <Flex>
        <Box>
          <Text fontSize="x-large">{assignment.projectName}</Text>
          <Text fontSize="medium">{assignment.projectId}</Text>
          <Text fontSize="medium">{formatDate(assignment.closingTime)}</Text>
        </Box>
        <Spacer />
        <Center>
          <HStack>
            <Button as={LinkWithPretend} to={"/graphs/" + assignment.projectId}>
              Graphs
            </Button>
            <Button
              as={LinkWithPretend}
              to={"/extensionadminlist/" + assignment.projectId}
            >
              Extensions
            </Button>
            <Button>Grade</Button>
            <Button onClick={onEditOpen}>Edit</Button>
          </HStack>
        </Center>
      </Flex>
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <AssignmentEditModal assignment={assignment} />
      </Modal>
    </Box>
  );
}

export default function AssignmentAdminList() {
  const toast = useToast();
  const view = loadingFrame(
    useAssignmentQuery(),
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (assignments: Array<Assignment>) => (
      <>
        {assignments.map((assignment: Assignment) => (
          <AssignmentItem assignment={assignment} key={assignment.projectId} />
        ))}
      </>
    ),
  );
  return (
    <SlimContainer>
      <VStack divider={<StackDivider />}>{view}</VStack>
    </SlimContainer>
  );
}
