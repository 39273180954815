import { Outlet } from "react-router-dom";
import NavBar from "@/components/DefaultHeader";
import SlimContainer from "@/components/SlimContainer";

import AssignmentIncludedNavBar from "@/components/AssignmentHeader";
import { useRef } from "react";
import { Divider } from "@chakra-ui/react";
import useSize from "@react-hook/size";
import { LinkWithPretend } from "./lib/pretend";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="text-gray-500 space-y-2">
      <Divider className="my-5" />
      <SlimContainer>
        <p>
          By using the autograder, you agree that you will not try to circumvent
          autograder limitations to gain an unfair advantage over other students
          in any way. Doing so constitutes an Honor Code violation and will be
          directly forwarded to the{" "}
          <a href="https://bulletin.engin.umich.edu/rules/">
            Engineering Honor Council
          </a>
          .
        </p>
        <p>
          Questions?{" "}
          <LinkWithPretend to="/faq">Click here for FAQ</LinkWithPretend>.
        </p>

        <p>
          Bug reports? Feature requests?{" "}
          <a href="mailto:eecs281ag@umich.edu">eecs281ag@umich.edu</a>
        </p>

        <p className="mb-5">
          Copyright © 2009–{currentYear} College of Engineering at the
          University of Michigan, Ann Arbor, MI 48109.
        </p>
      </SlimContainer>
    </div>
  );
}
export default function Layout() {
  return (
    <>
      <header className="sticky top-0 z-50 shadow bg-inherit">
        <NavBar />
      </header>

      <Outlet />

      <footer className="text-gray-500 space-y-2">
        <Footer />
      </footer>
    </>
  );
}

export function AssignmentLayout() {
  const headerRef = useRef(null);
  const [width, height] = useSize(headerRef);
  return (
    <>
      <header className="fixed z-50 shadow bg-inherit" ref={headerRef}>
        <AssignmentIncludedNavBar />
      </header>
      <div className="w-full top-0 left-0" style={{ height: height }}></div>

      <Outlet />
    </>
  );
}
