import { Text, VStack, StackDivider, Box } from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import SlimContainer from "@/components/SlimContainer";
const dependencyMap = ({ name, url }: { name: string; url: string }) => (
  <Text fontSize="medium" key={url}>
    <Link href={url} isExternal key={url}>
      - {name}
    </Link>
  </Text>
);
const frontEndDependencies = [
  { name: "ReactJS", url: "https://react.dev/" },
  { name: "Chakra UI", url: "https://v2.chakra-ui.com/" },
  { name: "Zustand", url: "https://github.com/pmndrs/zustand" },
];
const backEndDependencies = [
  { name: "FastAPI", url: "https://fastapi.tiangolo.com/" },
  { name: "Pydantic", url: "https://docs.pydantic.dev/latest/" },
  { name: "SQLAlchemy", url: "https://www.sqlalchemy.org/" },
  { name: "Redis", url: "https://redis.io/" },
];
const credits = ["Dr. David Paoletti", "Waleed Khan", "Gavin Li", "Patrick Li"];
export default function About() {
  const nodeEnv = process.env.NODE_ENV;
  const buildVersion = process.env.REACT_APP_BUILD_VERSION;
  return (
    <SlimContainer>
      <VStack divider={<StackDivider />} align="left">
        <Box>
          <Text fontSize="x-large">Front End Dependencies</Text>
          <Text fontSize="large">
            The front end is made possible and built mainly based on:
          </Text>
          {frontEndDependencies.map(dependencyMap)}
        </Box>

        <Box>
          <Text fontSize="x-large">Back End Dependencies</Text>
          <Text fontSize="large">
            The back end is made possible and built mainly based on:
          </Text>
          {backEndDependencies.map(dependencyMap)}
        </Box>
        <Box>
          <Text fontSize="x-large">Build Information</Text>
          <Text fontSize="large">Node Environment: {nodeEnv}</Text>
          <Text fontSize="large">Build Version: {buildVersion}</Text>
        </Box>
        <Box>
          <Text fontSize="x-large">Credits</Text>
          <Text fontSize="large">
            This project would not be possible without the efforts of the
            following contributors:
          </Text>
          {credits.map((name: string) => (
            <>
              <Text fontSize="medium">- {name}</Text>
            </>
          ))}
        </Box>
      </VStack>
    </SlimContainer>
  );
}
