import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import alertMsgStyle from "./error";

export type User = {
  uniqname: string;
  fullname: string;
  umid: string;
  canvasId: string;
  section: string;
  lateDaysLeft: string;
  extraSubmitsPerDay: string;
};

export const isInstructor = (user: User | null | undefined) =>
  user !== null && user !== undefined && user.section === "STAFF";

const queryUser = async () => {
  const response = await fetch("/api/v1/account/me");
  if (response.status !== 200) {
    return null;
  }
  const user = await response.json();
  return user as User;
};

const loginUser = async (searchParam: string) => {
  const response = await fetch("/api/v1/auth/token" + searchParam);
  if (response.status !== 200) {
    throw new Error(alertMsgStyle("Login Failed. ", response.status));
  }
  return await queryUser();
};

const logoutUser = async () => {
  const response = await fetch("/api/v1/auth/logout", { method: "POST" });
  if (response.status === 200) {
  }
  return response;
};

const queryUserList = async () => {
  const response = await fetch("/api/v1/account/");
  const userList = await response.json();
  return userList as Array<User>;
};

export const queryUserKeys = ["user", "account"];
export const useUserQuery = () =>
  useQuery({
    queryKey: queryUserKeys,
    queryFn: queryUser,
    staleTime: Infinity,
  });

export const queryUserListKeys = ["user", "userList"];
export const useUserListQuery = () =>
  useQuery({
    queryKey: queryUserListKeys,
    queryFn: queryUserList,
    staleTime: 60 * 1000,
  });

export const useUserLogin = (searchParam: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => loginUser(searchParam),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["user"] }),
  });
};

export const useUserLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => logoutUser(),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["user"] }),
  });
};

export const useUserAdd = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (uniqname: string) => {
      return fetch("/api/v1/account/" + uniqname, {
        method: "post",
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "userList"],
      }),
  });
};

export const useUserDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (uniqname: string) => {
      return fetch("/api/v1/account/" + uniqname, {
        method: "delete",
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "userList"],
      }),
  });
};

export function UserLevel({
  notLogIn,
  student,
  staff,
}: {
  notLogIn: ReactElement;
  student: ReactElement;
  staff: ReactElement;
}) {
  const { data: user } = useUserQuery();
  if (user === null) {
    return notLogIn;
  }
  if (isInstructor(user)) {
    return staff;
  }
  return student;
}
