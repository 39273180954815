import {
  alertError,
  CircularPendingElement,
  loadingFrame,
  QuestionMarkElement,
  useAlertError,
} from "@/components/LoadingFrame";
import SlimContainer from "@/components/SlimContainer";
import {
  Extension,
  useExtensionApply,
  useQueryExtension,
} from "@/lib/extension";
import { useParams } from "react-router-dom";
import {
  Box,
  Spacer,
  Text,
  VStack,
  HStack,
  StackDivider,
  Button,
  Center,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { User, useUserListQuery } from "@/lib/user";
import {
  useForm,
  useController,
  SubmitHandler,
  UseControllerProps,
} from "react-hook-form";
import { Select, Props } from "chakra-react-select";

interface StudentSelectionGroup {
  label: string;
  value: string;
}
function options(user: User) {
  const label = user.uniqname;
  const value = user.uniqname;
  return { label: label, value: value } as StudentSelectionGroup;
}

interface FormValues {
  studentSelected: StudentSelectionGroup | null;
  closingTime: string | null;
  comments: string;
}

const defaultValues: FormValues = {
  studentSelected: null,
  closingTime: null,
  comments: "",
};
type ControlledSelectProps = UseControllerProps<FormValues> &
  Props & {
    label: string;
  };

export const StudentSelect = ({
  control,
  name,
  id,
  label,
  rules,
  ...props
}: ControlledSelectProps) => {
  const { data: userList } = useUserListQuery();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
  });

  return (
    <FormControl py={4} isInvalid={!!error} id={id}>
      <FormLabel>{label}</FormLabel>

      <Select
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        options={userList?.map(options)}
        {...props}
      />

      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

function NewExtensionFormModal({ assignmentId }: { assignmentId: string }) {
  const extensionApply = useExtensionApply();
  useAlertError(extensionApply);
  const { control, handleSubmit, register } = useForm<FormValues>({
    defaultValues,
  });
  const submit: SubmitHandler<FormValues> = async (data) => {
    const newExtension = {
      uniqname: data.studentSelected?.value,
      projectId: assignmentId,
      closingTime: data.closingTime + "T23:59:59",
      comments: data.comments,
    } as Extension;
    extensionApply.mutate({ ...newExtension });
  };
  return (
    <ModalContent as="form" onSubmit={handleSubmit(submit)}>
      <ModalHeader>
        Grant New Extension for {assignmentId}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody margin={2}>
        <FormControl>
          <StudentSelect
            control={control}
            name="studentSelected"
            id="uniqname"
            placeholder="Uniqname"
            label="Uniqname"
            rules={{ required: "Please select a student." }}
          />
          <FormHelperText>Receiver of the extension.</FormHelperText>
          <FormLabel>Date</FormLabel>
          <Input
            placeholder="Select Date and Time"
            size="md"
            type="date"
            id="closingTime"
            {...register("closingTime", {
              required: "This is required",
            })}
          />
          <FormHelperText>
            The student will be able to submit until the 23:59:59 of this date.
          </FormHelperText>
          <FormLabel>Comments</FormLabel>
          <Input
            placeholder="Comments for extension"
            size="md"
            id="comments"
            {...register("comments", {
              required: "This is required",
            })}
          />
          <FormHelperText>Comments for this extension</FormHelperText>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="teal"
          type="submit"
          isLoading={extensionApply.isPending}
        >
          Confirm
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

const extensionItemView = (extension: Extension) => (
  <Box
    width="100%"
    key={
      extension.uniqname +
      extension.projectId +
      extension.closingTime +
      extension.extensionGranter +
      extension.createdAt
    }
  >
    <HStack>
      <Box>
        <Text fontSize="xx-large">
          {extension.projectId}: {extension.uniqname}
        </Text>
        <Text fontSize="medium" colorScheme="gray">
          Granted by: {extension.extensionGranter}
        </Text>
        <Text fontSize="medium" colorScheme="gray">
          Comments: {extension.comments}
        </Text>
      </Box>
      <Spacer />
      <Box>
        <Text>Until: {extension.closingTime}</Text>
      </Box>
    </HStack>
  </Box>
);

export default function ExtensionAdminList() {
  const { assignmentId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const extensionQuery = useQueryExtension(assignmentId);
  const toast = useToast();
  const extensionView = loadingFrame(
    extensionQuery,
    <CircularPendingElement />,
    <QuestionMarkElement />,
    (err: Error) => alertError(toast, err),
    (extensions: Array<Extension>) => (
      <VStack divider={<StackDivider />} width="100%">
        {extensions.map(extensionItemView)}
      </VStack>
    ),
  );
  return (
    <SlimContainer>
      {extensionView}
      <Center>
        <Button colorScheme="teal" onClick={onOpen}>
          + New Extension
        </Button>
      </Center>{" "}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <NewExtensionFormModal assignmentId={assignmentId || ""} />
      </Modal>
    </SlimContainer>
  );
}
