import { Link, useSearchParams } from "react-router-dom";
import { isInstructor, useUserQuery } from "./user";
import { useNavigate } from "react-router-dom";

export const usePretend = (): {
  pretend: string | null;
  setPretend: (pretend: string | null) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    pretend:
      searchParams.get("pretend") === null
        ? null
        : (searchParams.get("pretend") as string),
    setPretend: (pretend: string | null) => {
      const param = searchParams;
      if (pretend === null) {
        param.delete("pretend");
      } else {
        param.set("pretend", pretend);
      }
      setSearchParams(param);
    },
  };
};

export const useCheckPretend = () => {
  const { pretend } = usePretend();
  const { isFetching, data: user } = useUserQuery();
  const navigate = useNavigate();
  if (!isFetching && !isInstructor(user) && pretend !== null) {
    navigate("/");
  }
};

export const pretendUrl = (url: string, pretend: string | null) => {
  if (pretend === null) {
    return url;
  } else {
    return url + "?pretend=" + pretend;
  }
};

export const LinkWithPretend = (props: any) => {
  const { pretend } = usePretend();

  return <Link {...props} to={pretendUrl(props.to, pretend)} />;
};
