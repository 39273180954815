import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import alertMsgStyle from "./error";

export type Server = {
  hostname: string;
  isPrimary: boolean;
  isActive: boolean;
};

export type Grader = {
  hostname: string;
  graderId: string;
  finalGrader: boolean;
  gradingProjectId: string | null;
  gradingUniqname: string | null;
  gradingTimestamp: string | null;
};

export const useServerList = () => {
  return useQuery({
    queryKey: ["user", "server"],
    queryFn: async () => {
      const response = await fetch("/api/v1/server/");
      if (response.status !== 200) {
        throw new Error(
          alertMsgStyle("Cannot fetch server list. ", response.status),
        );
      }
      return (await response.json()) as Array<Server>;
    },
    refetchOnWindowFocus: false,
  });
};

export const useGraderList = (hostname: string) => {
  return useQuery({
    queryKey: ["user", "server", "grader", hostname],
    queryFn: async () => {
      const response = await fetch("/api/v1/grader/" + hostname);
      if (response.status !== 200) {
        throw new Error(
          alertMsgStyle(
            "Cannot fetch grader list of " + hostname + ". ",
            response.status,
          ),
        );
      }
      return (await response.json()) as Array<Grader>;
    },
    refetchOnWindowFocus: false,
  });
};

export const useServerActivationToggle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (server: Server) => {
      const response = await fetch("/api/v1/server/" + server.hostname, {
        method: "put",
        body: JSON.stringify(server),
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
      });
      if (response.status !== 200) {
        throw new Error(
          alertMsgStyle("Cannot toggle server activation.", response.status),
        );
      }
      return (await response.json()) as Server;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["user", "server"],
      });
    },
  });
};
