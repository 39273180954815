import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { pretendUrl, usePretend } from "./pretend";
import alertMsgStyle from "./error";

export type Assignment = {
  projectId: string;
  projectName: string;
  closingTime: string;
  hardClosingTime: string | null;
  dailySubmission: number;
  isVisible: string;
  hasScoreboard: string;
  gitUrl: string;
  bugsForExtraSubmit: string;
  testDir: string;
};

export const queryAssignment = async (pretend: string | null) => {
  // console.log("Query Sent");
  const url = pretendUrl("/api/v1/assignment/", pretend);
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error(alertMsgStyle("Assignment Fetch Failed.", response.status));
  }
  const assignments = await response.json();
  return Object.assign(new Array<Assignment>(), assignments);
};

export const queryAssignmentKeys = (pretend: string | null) => [
  "user",
  "assignment",
  pretend,
];
export const useAssignmentQuery = () => {
  const { pretend } = usePretend();
  return useQuery({
    queryKey: queryAssignmentKeys(pretend),
    queryFn: async () => await queryAssignment(pretend),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const isProject = (assignment: Assignment) => {
  return assignment.projectId.indexOf("P") >= 0;
};

export const detailsPage = (assignment: Assignment) => {
  return "/assignment/" + assignment.projectId + "/";
};

export const useAssignmentEdit = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      assignmentId,
      assignment,
    }: {
      assignmentId: string;
      assignment: Assignment;
    }) => {
      const response = await fetch("/api/v1/assignment/" + assignmentId, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(assignment),
      });
      if (response.status !== 200) {
        throw new Error(alertMsgStyle("Submission Failed", response.status));
      }
      return (await response.json()) as Assignment;
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["user", "assignment"] }),
  });
};
