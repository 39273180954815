import {
  Text,
  CircularProgress,
  Center,
  CreateToastFnReturn,
  useToast,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";

export const QuestionMarkElement = () => {
  return (
    <Center>
      <Text size="70px" paddingY="100px" fontSize="70px" color="grey">
        ?
      </Text>
    </Center>
  );
};

export const alertError = (toast: CreateToastFnReturn, err: Error) => {
  const id = err.name + err.message;
  if (!toast.isActive(id)) {
    toast({
      id: id,
      title: err.name,
      description: err.message,
      status: "error",
      duration: 8000,
      isClosable: true,
      position: "top-right",
    });
  }
};

export const useAlertError = ({ error }: { error: Error | null }) => {
  const toast = useToast();
  if (error) {
    alertError(toast, error);
  }
};
export const loadingFrame = (
  { isFetching, error, data }: UseQueryResult,
  PendingElement: JSX.Element,
  ErrorElement: JSX.Element,
  onError: (err: Error) => void,
  display: (data: any) => JSX.Element,
) => {
  if (isFetching) {
    return PendingElement;
  }
  if (error) {
    onError(error);
    return ErrorElement;
  }
  return display(data);
};

export const CircularPendingElement = () => {
  return (
    <Center>
      <CircularProgress isIndeterminate size="70px" paddingY="100px" />
    </Center>
  );
};
