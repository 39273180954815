import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import cpp from "react-syntax-highlighter/dist/esm/languages/hljs/cpp";
import makefile from "react-syntax-highlighter/dist/esm/languages/hljs/makefile";
import plaintext from "react-syntax-highlighter/dist/esm/languages/hljs/plaintext";
import { Box, useColorModeValue } from "@chakra-ui/react";
import {
  dracula,
  arduinoLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import ReactDiffViewer from "react-diff-viewer-continued";
import useSize from "@react-hook/size";
import { useRef } from "react";
export function CodeBlock({
  fileName,
  codeString,
}: {
  fileName: string;
  codeString: string;
}) {
  SyntaxHighlighter.registerLanguage("cpp", cpp);
  SyntaxHighlighter.registerLanguage("makefile", makefile);
  SyntaxHighlighter.registerLanguage("plaintext", plaintext);
  var language = "plaintext";
  if (
    fileName.endsWith(".c") ||
    fileName.endsWith(".h") ||
    fileName.endsWith(".cpp") ||
    fileName.endsWith(".hpp") ||
    fileName.endsWith(".cxx") ||
    fileName.endsWith(".cc")
  ) {
    language = "cpp";
  } else if (fileName.endsWith("Makefile")) {
    language = "makefile";
  }
  const style = useColorModeValue(arduinoLight, dracula);
  const lineNumberColor = useColorModeValue(
    "var(--chakra-colors-gray-400)",
    "var(--chakra-colors-gray-500)",
  );
  return (
    <Box borderColor="black" shadow="lg" borderRadius={5}>
      <SyntaxHighlighter
        language={language}
        style={style}
        showLineNumbers={language !== "plaintext"}
        lineNumberStyle={{ color: lineNumberColor, textAlign: "left" }}
      >
        {codeString}
      </SyntaxHighlighter>
    </Box>
  );
}

export function Diff({
  codeStringLeft,
  codeStringRight,
}: {
  codeStringLeft: string;
  codeStringRight: string;
}) {
  const darkTheme = useColorModeValue(false, true);
  const diffRef = useRef(null);
  const [width, height] = useSize(diffRef);
  return (
    <Box width="100%" ref={diffRef}>
      <ReactDiffViewer
        oldValue={codeStringLeft}
        newValue={codeStringRight}
        splitView={width > 600}
        useDarkTheme={darkTheme}
      />
    </Box>
  );
}
