export function stringToDate(s: string) {
  const res = new Date(s);
  return res;
}

export function dateToString(date: Date) {
  return date.toLocaleString("en-us");
}

export function formatDate(s: string) {
  return dateToString(stringToDate(s));
}
