import SlimContainer from "@/components/SlimContainer";
import { VStack, Text, Box } from "@chakra-ui/react";

export function NotFound() {
  return (
    <SlimContainer>
      <VStack>
        <Box>
          <Text fontSize="xxx-large">404</Text>
        </Box>
        <Box>
          <Text fontSize="large">Page not found</Text>
        </Box>
      </VStack>
    </SlimContainer>
  );
}
